<template>
  <div>
    <v-row class="pb-1 mx-2">
      <v-col
        cols="12"
        class="pa-4 text-center"
      >
        <v-avatar
          size="200"
          tile
          style="margin-top:2vh;"
        >
          <img
            style="cursor: pointer"
            :src="
              croppedCampaignLogoUrl === null
                ? campaign.logo === null ? 'd-clamp-holder.jpg' : campaign.logo.url
                : croppedCampaignLogoUrl
            "
            @click="$refs.campaignLogo.click()"
          >
        </v-avatar>
        <input
          ref="campaignLogo"
          style="display: none"
          type="file"
          accept="image/*"
          @change="addNewImgToCropper"
        >
      </v-col>
      <v-col cols="12">
        <v-dialog
          v-model="cropperDialog"
          width="600"
        >
          <v-card class="pa-4">
            <v-col cols="12">
              <cropper
                ref="cropper"
                :src="imgUrl"
                :stencil-props="{
                  aspectRatio: 300/250,
                }"
                :minAspectRatio="300/250"
                :maxAspectRatio="300/250"
                class="cropper"
              />
            </v-col>
            <v-col
              cols="12"
              class="text-right"
            >
              <v-btn
                color="primary"
                @click="cropImage"
              >
                crop
              </v-btn>
            </v-col>
          </v-card>
        </v-dialog>
      </v-col>
      <v-col cols="12">
        <v-text-field
          v-model="localCampaign.name"
          outlined
          maxlength="50"
          label="Campaign Name *"
        />
      </v-col>
      <v-col cols="12">
        <v-text-field
          v-model="localCampaign.kNumber"
          outlined
          maxlength="50"
          label="K Number"
          class="mt-n7"
        />
      </v-col>
      <v-col cols="12" class="mt-n7">
        <v-menu
          :close-on-content-click="false"
          transition="scale-transition"
          offset-y
          min-width="auto"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-combobox
              :value="sanitizedDate"
              outlined
              range
              label="Start/End Date"
              append-icon="mdi-calendar"
              readonly
              v-bind="attrs"
              v-on="on"
            />
          </template>
          <v-date-picker
            v-model="campaignDatepickerVal.date"
            @change="updateLocalCampaignDate()"
            range
            no-title
            scrollable
          />
        </v-menu>
      </v-col>
      <v-col cols="12">
        <v-textarea
          v-model="localCampaign.description"
          maxlength="1000"
          label="Description (Include search tags)"
          rows="1"
          auto-grow
          outlined
          hide-details
          class="mt-n7 textarea-padded"
        />
      </v-col>
      
      <v-col
        cols="6"
        class="text-left"
      >
        <v-btn
          v-if="$store.state.Permissions.CampaignDelete"
          color="red"
          @click="showCampaignDeleteFormDialog = true"
        >
          <v-icon color="white">
            mdi-delete
          </v-icon>
        </v-btn>
      </v-col>
      <v-col
        cols="6"
        class="text-right"
      >
        <v-btn
          @click="editCampaign"
          :disabled="!allowSave"
          color="primary"
        >
          save
        </v-btn>
      </v-col>
    </v-row>

    <!-- Campaign Delete Form Dialog -->
    <v-dialog
      v-model="showCampaignDeleteFormDialog"
      width="600"
    >
      <campaign-delete-form 
        :campaign="campaign"
        @deleteSuccessHandler="deleteSuccessHandler"
        @close="showCampaignDeleteFormDialog = false"
      />
    </v-dialog>
  </div>
</template>

<script>
  // Components
  import CampaignDeleteForm from '@/components/campaign/DeleteForm'

  // Services
  import CampaignController from '@/services/controllers/Campaign'

  // External libraries
  import moment from 'moment'
  import { Cropper } from 'vue-advanced-cropper'
  import 'vue-advanced-cropper/dist/style.css'

  export default {
    components: { 
      CampaignDeleteForm, 
      Cropper 
    },

    props: {
      campaign: {
        type: Object,
        required: true,
      },
    },

    data () {
      return {
        localCampaign: null,
        showCampaignDeleteFormDialog: false,
        croppedCampaignLogoUrl: null,
        campaignDateRangePicker: null,
        uploadedCampaignLogo: null,
        cropperDialog: false,
        imgUrl: null,
        mediaId: null,
        campaignDatepickerVal: { date: [] },
      }
    },

    computed: {
      allowSave() {
        if((JSON.stringify(this.localCampaign) !== JSON.stringify(this.campaign)) || this.mediaId !== null) {
          return true
        }

        return false
      },

      sanitizedDate() {
        if(this.localCampaign.startDate && this.localCampaign.endDate) {
          return moment(this.localCampaign.startDate).format('DD MMM YYYY')  + ' - ' + moment(this.localCampaign.endDate).format('DD MMM YYYY') 
        }

        return 'Not Set'
      }
    },
    
    created () {
      // check if campaign has logo
      if(this.campaign.logo){
        this.mediaId = this.campaign.logo.id
      }
      // Make local copy
      this.localCampaign = JSON.parse(JSON.stringify(this.campaign))

      // Destructure date and append to variables for date picker
      const { startDate = null, endDate = null } = this.localCampaign

      if (startDate && endDate) {
        this.campaignDatepickerVal.date[0] = moment(startDate).format('YYYY-MM-DD')
        this.campaignDatepickerVal.date[1] = moment(endDate).format('YYYY-MM-DD')
      } 
      else {
        this.campaignDatepickerVal.date = null
      }
    },

    methods: {
      // Purpose: Handler function for campaign end points
      async editCampaign() {
        // Change details (Only if new)
        if(this.allowSave) {
          await this.editCampaignDetails()
          if(this.mediaId !== null) {
            await this.editCampaignLogo()
          }
          this.$root.$emit('snackbarSuccess', 'Campaign edited successfully')
          this.$emit('getCampaignByIdFromChild')
          this.$emit('closeEditDialog')
        }
      },

      // Purpose: Post campaign details
      async editCampaignDetails() {
        const res = await CampaignController.editCampaign({
          id: this.localCampaign.id,
          name: this.localCampaign.name,
          kNumber: this.localCampaign.kNumber,
          description: this.localCampaign.description,
          startDate: this.localCampaign.startDate,
          endDate: this.localCampaign.endDate
        }).catch(err => {
          this.$root.$emit('snackbarError', ''+ err.response.data.message)
        })

        return res
      },

      // Purpose: Post campaign logo
      async editCampaignLogo() {
        // Safeguard if empty image
        if(this.mediaId === null || this.mediaId === undefined) return
        else {
        await CampaignController.setLogo({
          mediaId: this.mediaId,
          campaignId: this.localCampaign.id,
        })
        .then((res) => {
          this.$root.$emit('snackbarSuccess', 'Campaign logo updated successfully')
        
          this.campaign.logo.url = this.croppedCampaignLogoUrl
          this.croppedCampaignLogoUrl = null
          this.imgUrl = null
          return res.data
        })
        .catch((err) => {
          this.$root.$emit('snackbarError', ''+ err.response.data.message)
        })
      }
      },

      // Purpose: Update campaigns, and close edit dialog
      deleteSuccessHandler() {
        this.$emit('getCampaignByIdFromChild')
        this.$emit('closeEditDialog')
      },

      // Purpose: Update start/end date of the local campaign variable
      updateLocalCampaignDate() {
        if(this.campaignDatepickerVal.date) {
          this.localCampaign.startDate = this.campaignDatepickerVal.date[0]
          this.localCampaign.endDate = this.campaignDatepickerVal.date[1]
        }
      },

      // Purpose: Crop image selected for campaign logo
      cropImage() {
        const { canvas } = this.$refs.cropper.getResult()
        let resultFromApi
        const checkForResult = setInterval(() => {
          if (resultFromApi !== undefined) {
            clearInterval(checkForResult)
            this.mediaId = resultFromApi.id
            this.croppedCampaignLogoUrl = resultFromApi.url
            this.imgUrl = null
            this.uploadedCampaignLogo = null
            this.cropperDialog = false
          }
        }, 100)

        if (canvas) {
          const form = new FormData()

          canvas.toBlob(blob => {
            const reader = new FileReader()

            reader.readAsDataURL(blob)

            reader.onloadend = () => {
              const base64data = reader.result

              form.append('fileBase64', base64data)
              form.append('campaignId', this.localCampaign.id)

              CampaignController.uploadB64(form)
              .then(b64res => {
                resultFromApi = b64res.data
              })
              .catch(() => {
                clearInterval(checkForResult)
              })
            }
          }, 'image/png')
        }
      },

      // Purpose: Append image selected to cropper container
      addNewImgToCropper(e) {
        this.uploadedCampaignLogo = Object.values(e.target.files)[0]
        this.imgUrl = this.imgUrl ? this.uploadedCampaignLogo : URL.createObjectURL(this.uploadedCampaignLogo)
        this.cropperDialog = true
      },
    },
  }
</script>

<style scoped>
.cropper {
  width: 900px;
  height: 750px;
}
</style>
