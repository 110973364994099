<template>
  <v-card v-if="sliderShouldShow" :background-color="(getGrandTotal == 0 ? 'gray' : 'primary')" :width="type === 'vertical' ? '50px' : 'min-content'" style="display: flex; flex-direction: column;">
    <v-tooltip :disabled="!tooltip" bottom>
      <template v-slot:activator="{ on }">
        <div v-on="type === 'horizontal' ? on : {}" style="display: flex" :style="{ flexDirection: type === 'vertical' ? 'column' : 'row-reverse' }">
          <div v-if="type === 'vertical'" class="text-button" style="color:gray;text-align: center;width: 100%;">
            SOV
          </div>
          <v-expand-x-transition>
            <div v-show="isSliderExpanded || lockSliderView" :style="{width: lockSliderView ? type == 'vertical' ? '100%' : 'calc(100% - 50px)' : '100px'}">
              <v-slider v-model="sliderValue" 
                style="width: 100%;padding:0px 5px"
                :style="{ marginTop: type === 'vertical' ? '' : '0px' }"
                  :vertical="type === 'vertical'" 
                  :track-color="(getGrandTotal == 0 ? 'gray' : 'tertiary')"
                  hide-details 
                  step="1"
                  @input="onSliderChange"></v-slider>
            </div>
          </v-expand-x-transition>
          <v-btn v-if="!lockSliderView" small fab icon @click="setSliderExpanded(!isSliderExpanded)">
            <v-icon>{{ isSliderExpanded ? 'mdi-chevron-left' : 'mdi-chevron-right' }}</v-icon>
          </v-btn>
          <div style="padding: 2px;width:50px">
            <v-text-field 
              class="centered-input" 
              :style="{ marginTop: type === 'vertical' ? '2px' : '', 
                        marginBottom: type === 'vertical' ? '1px' : '' }"
              v-model="sliderValue" 
              dense 
              hide-details
              outlined
              height="34px"></v-text-field>
          </div>
        </div>
      </template>
      <span>Share of Voice</span>
    </v-tooltip>
    <v-expand-transition>
      <div v-show="isAnySliderDirty" style="display: flex;align-items: center; background-color: #ffffff0d"
        :style="{ flexDirection: type === 'vertical' ? 'column' : 'row' }">
        <v-btn icon small @click="saveSliders" :style="{ marginTop: type === 'vertical' ? '10px' : '0px' }">
          <v-icon>mdi-content-save</v-icon>
        </v-btn>
        <v-btn icon small @click="resetToOriginal" :style="{ marginBottom: type === 'vertical' ? '10px' : '0px' }">
          <v-icon>mdi-refresh</v-icon>
        </v-btn>
      </div>
    </v-expand-transition>
  </v-card>
</template>

<script>
function clamp(val, min, max) {
  return Math.max(min, Math.min(max, val));
}

export default {
  data() {
    return {
      originalValues: null,
      sliderValue: 0,
      isSliderExpanded: false,
    };
  },
  props: {
    campaign: {
      type: Object,
      required: true
    },
    sliderGroups: {
      type: Array,
      required: true
    },
    interLinked: {
      type: Boolean,
      default: false
    },
    type: {
      type: String,
      default: 'vertical'
    },
    tooltip: {
      type: Boolean,
      default: false
    },
    lockSliderView: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    getValue() {
      let slider = this.sliders.find((slider) => slider.campaignId === this.campaign.id);
      return slider ? Number(slider.value) : 0;
    },
    sliders() {
      return this.sliderObj?.sliders || [];
    },
    sliderObj() {
      return this.sliderGroups.find((group) => group.groupId === this.campaign.groupId);
    },
    sliderShouldShow() {
      return this.sliders.some((slider) => slider.campaignId === this.campaign.id);
    },
    isAnySliderDirty() {
      if (!this.interLinked) {
        return this.sliders.some((slider) => slider.campaignId === this.campaign.id && slider.isDirty);
      }
      return this.sliders.some((slider) => slider.isDirty);
    },
    getGrandTotal() {
      let abc = this.sliders.reduce((total, slider) => total + Math.round(slider.value), 0);
      console.log("abc", abc);
      return abc;
    }
  },
  watch: {
    getValue: {
      immediate: true,
      handler(newValue) {
        this.sliderValue = newValue;
      }
    },
    isAnySliderDirty(newValue) {
      if (newValue) {
        this.setSliderExpanded(true);
      }
    },
    isSliderExpanded(newValue) {
      localStorage.setItem('isSliderExpanded', JSON.stringify(newValue));
      this.$root.$emit('sliderExpandedChanged', newValue);
    }
  },
  created() {
    this.loadSliderExpandedState();
    this.$root.$on('sliderExpandedChanged', this.updateSliderExpandedState);
  },
  beforeDestroy() {
    this.$root.$off('sliderExpandedChanged', this.updateSliderExpandedState);
  },
  mounted() {
    console.log("item", this.campaign.id);
  },
  methods: {
    loadSliderExpandedState() {
      const savedState = localStorage.getItem('isSliderExpanded');
      if (savedState !== null) {
        this.isSliderExpanded = JSON.parse(savedState);
      }
    },
    updateSliderExpandedState(newState) {
      this.isSliderExpanded = newState;
    },
    setSliderExpanded(value) {
      this.isSliderExpanded = value;
    },
    saveSliders() {
      this.originalValues = JSON.parse(JSON.stringify(this.sliderGroups));
      if (!this.interLinked) {
        let slider = this.sliders.find((slider) => slider.campaignId === this.campaign.id);
        if (slider) slider.shouldSave = true;
      }
      this.sliderObj.shouldSave = true;
      this.resetDirty();
    },
    resetToOriginal() {
      //TODO: FETCH ORIGINAL VALUES FROM JSON IF NULL
      this.sliderGroups.forEach((sliderGroup) => {
        sliderGroup.sliders.forEach((slider) => {
          let originalValue = this.originalValues.find((originalValue) => originalValue.groupId === sliderGroup.groupId);
          let shouldReset = this.interLinked || slider.campaignId === this.campaign.id;
          if (originalValue && shouldReset) {
            let originalSlider = originalValue.sliders.find((originalSlider) => originalSlider.campaignId === slider.campaignId);
            if (originalSlider) {
              slider.value = originalSlider.value;
            }
          }
        });
      });
      this.resetDirty();
    },
    resetDirty() {
      this.sliders.forEach((slider) => {
        if (!this.interLinked && slider.campaignId === this.campaign.id) {
          slider.isDirty = false;
        } else if (this.interLinked) {
          slider.isDirty = false;
        }
      });
      this.setSliderExpanded(false);
    },
    adjustSliders(recalcing) {
      if (!this.interLinked) return;
      let total = 100;
      let siblingsTotal = this.getSiblingsTotal();
      let grandTotal = this.getGrandTotal;
      console.log("grandTotal", grandTotal);
      let diff = grandTotal - total;

      for (let slider of this.sliders) {
        if (slider.campaignId !== this.campaign.id) {
          if (siblingsTotal === 0) {
            slider.value = recalcing ? 0 : 1;
            recalcing = false;
          } else {
            slider.value = Math.round(clamp(slider.value - (diff * (slider.value / siblingsTotal)), 0, 100));
          }
        }
      }

      grandTotal = this.getGrandTotal;
      if (grandTotal !== total && !recalcing) {
        this.adjustSliders(true);
      }
    },
    onSliderChange(value) {
      this.updateValue(value);
    },
    updateValue(value) {
      if (this.originalValues === null) {
        this.originalValues = JSON.parse(JSON.stringify(this.sliderGroups));
      }

      let slider = this.sliders.find((slider) => slider.campaignId === this.campaign.id);
      if (slider) {
        value = Math.round(value);
        slider.value = value;
        this.sliderObj.isDirty = true;
        slider.isDirty = true;
        this.adjustSliders();
      }
    },
    getSiblingsTotal() {
      return this.sliders.reduce((total, slider) => {
        return slider.campaignId !== this.campaign.id ? total + slider.value : total;
      }, 0);
    }
  }
};
</script>

<style scoped>
.centered-input>>>input {
  text-align: center;
  font-size: 12.5px;
}
</style>