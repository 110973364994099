var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-row',{staticClass:"pb-1 mx-2"},[_c('v-col',{staticClass:"pa-4 text-center",attrs:{"cols":"12"}},[_c('v-avatar',{staticStyle:{"margin-top":"2vh"},attrs:{"size":"200","tile":""}},[_c('img',{staticStyle:{"cursor":"pointer"},attrs:{"src":_vm.croppedCampaignLogoUrl === null
              ? _vm.campaign.logo === null ? 'd-clamp-holder.jpg' : _vm.campaign.logo.url
              : _vm.croppedCampaignLogoUrl},on:{"click":function($event){return _vm.$refs.campaignLogo.click()}}})]),_c('input',{ref:"campaignLogo",staticStyle:{"display":"none"},attrs:{"type":"file","accept":"image/*"},on:{"change":_vm.addNewImgToCropper}})],1),_c('v-col',{attrs:{"cols":"12"}},[_c('v-dialog',{attrs:{"width":"600"},model:{value:(_vm.cropperDialog),callback:function ($$v) {_vm.cropperDialog=$$v},expression:"cropperDialog"}},[_c('v-card',{staticClass:"pa-4"},[_c('v-col',{attrs:{"cols":"12"}},[_c('cropper',{ref:"cropper",staticClass:"cropper",attrs:{"src":_vm.imgUrl,"stencil-props":{
                aspectRatio: 300/250,
              },"minAspectRatio":300/250,"maxAspectRatio":300/250}})],1),_c('v-col',{staticClass:"text-right",attrs:{"cols":"12"}},[_c('v-btn',{attrs:{"color":"primary"},on:{"click":_vm.cropImage}},[_vm._v(" crop ")])],1)],1)],1)],1),_c('v-col',{attrs:{"cols":"12"}},[_c('v-text-field',{attrs:{"outlined":"","maxlength":"50","label":"Campaign Name *"},model:{value:(_vm.localCampaign.name),callback:function ($$v) {_vm.$set(_vm.localCampaign, "name", $$v)},expression:"localCampaign.name"}})],1),_c('v-col',{attrs:{"cols":"12"}},[_c('v-text-field',{staticClass:"mt-n7",attrs:{"outlined":"","maxlength":"50","label":"K Number"},model:{value:(_vm.localCampaign.kNumber),callback:function ($$v) {_vm.$set(_vm.localCampaign, "kNumber", $$v)},expression:"localCampaign.kNumber"}})],1),_c('v-col',{staticClass:"mt-n7",attrs:{"cols":"12"}},[_c('v-menu',{attrs:{"close-on-content-click":false,"transition":"scale-transition","offset-y":"","min-width":"auto"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
              var on = ref.on;
              var attrs = ref.attrs;
return [_c('v-combobox',_vm._g(_vm._b({attrs:{"value":_vm.sanitizedDate,"outlined":"","range":"","label":"Start/End Date","append-icon":"mdi-calendar","readonly":""}},'v-combobox',attrs,false),on))]}}])},[_c('v-date-picker',{attrs:{"range":"","no-title":"","scrollable":""},on:{"change":function($event){return _vm.updateLocalCampaignDate()}},model:{value:(_vm.campaignDatepickerVal.date),callback:function ($$v) {_vm.$set(_vm.campaignDatepickerVal, "date", $$v)},expression:"campaignDatepickerVal.date"}})],1)],1),_c('v-col',{attrs:{"cols":"12"}},[_c('v-textarea',{staticClass:"mt-n7 textarea-padded",attrs:{"maxlength":"1000","label":"Description (Include search tags)","rows":"1","auto-grow":"","outlined":"","hide-details":""},model:{value:(_vm.localCampaign.description),callback:function ($$v) {_vm.$set(_vm.localCampaign, "description", $$v)},expression:"localCampaign.description"}})],1),_c('v-col',{staticClass:"text-left",attrs:{"cols":"6"}},[(_vm.$store.state.Permissions.CampaignDelete)?_c('v-btn',{attrs:{"color":"red"},on:{"click":function($event){_vm.showCampaignDeleteFormDialog = true}}},[_c('v-icon',{attrs:{"color":"white"}},[_vm._v(" mdi-delete ")])],1):_vm._e()],1),_c('v-col',{staticClass:"text-right",attrs:{"cols":"6"}},[_c('v-btn',{attrs:{"disabled":!_vm.allowSave,"color":"primary"},on:{"click":_vm.editCampaign}},[_vm._v(" save ")])],1)],1),_c('v-dialog',{attrs:{"width":"600"},model:{value:(_vm.showCampaignDeleteFormDialog),callback:function ($$v) {_vm.showCampaignDeleteFormDialog=$$v},expression:"showCampaignDeleteFormDialog"}},[_c('campaign-delete-form',{attrs:{"campaign":_vm.campaign},on:{"deleteSuccessHandler":_vm.deleteSuccessHandler,"close":function($event){_vm.showCampaignDeleteFormDialog = false}}})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }